import React from "react";

import Layout from "../components/StandardLayout";
import { sky } from "mineral-ui-tokens";
import fans from "../images/undraw_fans_gr54.svg";
import PageHeading from "../components/PageHeading";
import HiItsMeHeader from "../components/hiItsMeHeader";

import css from "./index.module.scss";

const ColophonPage = () => (
  <Layout>
    <HiItsMeHeader />

    <nav className={css.navStack}>
      <PageHeading color={sky[60]} graphic={fans}>
        Colophon
      </PageHeading>
    </nav>
  </Layout>
);

export default ColophonPage;
