import React from "react";

import css from "./PageHeading.module.scss";

function PageHeading({ children, color, graphic }) {
  return (
    <div>
      <h1 className={css.title}>{children}</h1>
    </div>
  );
}

export default PageHeading;
